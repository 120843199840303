.Greeting {
    background-color: black;

    padding: 0;
    margin: 0;

}

#GreetingLogo{
    z-index: 2;
    position: absolute;

    animation: GreetingFadeIn 0.5s;
    background-color: white; 
    margin: 3%;

    height: 80pt;
    width: 80pt;
}

#greetingVideo{
    z-index: 1;
    object-fit: cover;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
}

#GreetingSlogan{
    z-index: 2;
    color: white;
    background-color: black;

    position: absolute;
    font-size: 5em;

    min-width: 300px;
    width: 25%;

    bottom: 0;
    right: 0;
    animation: GreetingFadeIn 1s;
    animation-delay: 1s;

    margin-bottom: 3%;
    margin-right: 5%;
    
    padding: 1%;

    animation: GreetingSloganKeyframes 13s;
}

@keyframes GreetingFadeIn {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes GreetingSloganKeyframes {
    0%{
        opacity: 0;
    }
    80%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}