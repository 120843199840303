.Contact{
    z-index: 100;
    background-color: black;

    position: relative;
    width: 100vw;
    height: 100vh;

    padding: 0;
    margin: 0;

    overflow-y: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}


.BackgoundText_Individual{
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 auto;
    white-space: nowrap;
}


.BackgoundText_Individual span{
    display: inline-block;
    position: relative;
    text-align: center;
    float: left;
    font-size: 5.5em;
    color: #ffffff8b;
    font-weight: bold;
    font-family: 'Montserrat', 'Mplus 1p', 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic', 'ＭＳ ゴシック' , 'MS Gothic', sans-serif;
    line-height: normal;
    transform:translateX(0%);

    animation: backgroundTextScrollAnimation 500s  linear infinite;
    animation-play-state: running;
}

.BackgoundText_Individual span:hover{
    color: black;
    background-color: #ffffff8b;
    display: block;
    animation-play-state: paused;
}

.contactBox{
    position: absolute;
    background-color: #ffffff;
    margin: auto;

    overflow-x: scroll;
    overflow-y: hidden;

    width: 40%;
    height: 30%;

    padding: 1.5rem;
}
.contactBox h1{
    font-family: 'Courier New', Courier, monospace;
}

table.customTable {
    width: 100%;
    text-align: left;


    font-family:'Times New Roman', Times, serif
  }
  
    table.customTable th {
    font-size: 2em;
    border-width: 30%;
    border-style: solid;
    border-radius: 10;
    padding: 1rem;

    background: black;
    color: white;
  }

  table.customTable td {
    font-size: 1.5em;
    border-width: 70%;
    padding: 5px;
  }
  
  table.customTable thead {
    background-color: #7EA8F8;
  }

@keyframes backgroundTextScrollAnimation {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

 



