*{
    margin: 0;
    padding: 0;
}

.Parent{
    overflow: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;

    width: 100vw;
    height: 100vh;
}

.Child{

    height: 100vh;
    display: flex;

    scroll-snap-align: start;

}